<template>
  <div style="padding-bottom: 20px; min-height: calc(100vh - 60px)">
    <div style="padding: 20px; background-color: #fff; border-radius: 10px; margin: 10px 0">
      <el-input v-model="nickname" style="width: 400px" size="mid"></el-input> <el-button type="primary" size="mid">搜 索</el-button>
      <el-button type="success" style="margin-left: 30px" size="mid" @click="$router.push('/front/myProduct')">查看我的购买</el-button>
    </div>
    <div style="margin: 10px 0">
      <el-card  v-for="item in tableData" :key="item.id" style="margin: 10px 0">
        <div style="display: flex">
          <div style="width: 140px" > <img :src="item.img" alt="" style="width: 100%; height: 160px; border-radius: 10px"></div>
          <div style="padding-left: 50px; flex: 1">
            <div style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px">
              <div><span style="margin-left: 20px">名字：{{ item.name }}</span></div>
              <div><span style="margin-left: 20px">商品数量：{{ item.pnumber }}</span></div>
              <div><b style="margin-right: 10px">商品介绍：</b>{{ item.description }}</div>
            </div>
            <div style="line-height: 30px;text-align: right">
              <div>{{ item.price }}&nbsp;&nbsp;¥</div>
              <div style="text-align: right;" ><el-button type="primary" @click="handleApply(item)">购买</el-button></div>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <div style="padding: 10px; background-color: #fff; border-radius: 10px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%" :close-on-click-modal="false">
      <el-descriptions class="margin-top" title="商品信息" :column="3"  border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-s-shop"></i>
            商品名
          </template>
          {{form.name}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-price-tag"></i>
            价格
          </template>
          {{form.price}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-shopping-cart-2"></i>
            数量
          </template>
          {{form.pnumber}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            描述
          </template>
          {{form.pnumber}}
        </el-descriptions-item>
      </el-descriptions>

      <el-form label-width="80px" size="small" style="width: 100%;margin-top: 30px">
        <el-form-item label="购买数量">
          <el-input v-model="form.number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="配送方式">
          <el-radio v-model="form.deliveryMethod" label="邮寄">邮寄</el-radio>
          <el-radio v-model="form.deliveryMethod" label="自取">自取</el-radio>
        </el-form-item>

        <el-form-item label="收货地址" v-if="form.deliveryMethod === '邮寄'">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="收件人" v-if="form.deliveryMethod === '邮寄'">
          <el-input v-model="form.recipient"></el-input>
        </el-form-item>
        <el-form-item label="总金额">
          <span> {{calculateTotalAmount()}} (优惠价格：{{preferential}})</span>
        </el-form-item>
        <el-form-item label="付款方式">
          <el-radio v-model="form.paymentMethod" label="现金支付">现金支付</el-radio>
          <el-radio v-model="form.paymentMethod" label="银联支付">银联支付</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "FrontHome",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      nickname: "",
      dialogFormVisible: false,
      form: {},
      preferential:0,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
    }
  },
  created() {
    this.load()
  },
  methods: {

    calculateTotalAmount() {
      if (this.form.number && this.form.price) {
        const totalAmount = this.form.number*this.form.price;
        let  data = totalAmount.toFixed(2);
        this.form.totalAmount = data;
        return data;
      } else {
        return '0.00';
      }
    },
    save() {
      this.form.id = null;
      this.form.preferential = this.preferential;
      this.request.post("/productsBuy", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    handleApply(item) {
      if (!this.user.id) {
        this.$message.warning("请登录后操作")
        return
      }
      // 获取最大优惠数据
      this.getPreferential();
      this.form = {productId: item.id}
      this.form = Object.assign(this.form,item )
      this.dialogFormVisible = true
    },
    load() {
      this.request.get("/products/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.nickname,
        }
      }).then(res => {
        if (res&&res.data) {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    getPreferential(){
      this.request.get("/article/preferential")
      .then((res)=>{
        if (res&&res.data){
          this.preferential = res.data.praise
        }
      })
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
  }
}
</script>

<style>

</style>
